import { fuelMapping, transmissionMapping, getObjKey } from '../../components/FindYourPerfectCar/utils';

const optionFiltering = {
  filteredFuelTypes: [],
  filteredTransmission: [],
  filteredBodyType: [],
};

const initialState = {
  // Step tracking
  currentStep: 1,

  // User selections
  type: 'Type',
  term: 'Term',
  mileage: 'Mileage',
  budget: 'Budget',
  minPayment: 100,
  maxPayment: 350,
  fuel: 'Fuel',
  transmission: 'Transmission',
  bodyTypeCategories: [],
  ...optionFiltering,
};

// eslint-disable-next-line default-param-last
const findYourPerfectCar = (state = initialState, action) => {
  let newState;

  if (action.type === 'RESET_FMPC_STATE') {
    if (Object.keys(action.payload).length !== 0) {
      return {
        fuel: getObjKey(
          fuelMapping,
          Array.isArray(action.payload.display_fueltype)
            ? action.payload.display_fueltype[0]
            : action.payload.display_fueltype,
        ),
        term: action.payload.term,
        type: action.payload.type,
        mileage: action.payload.mileage,
        transmission: getObjKey(transmissionMapping, action.payload.transmission),
        budget: `${action.payload.price_from}-${action.payload.price_to}`,
        bodyTypeCategories: action.payload.bodytype_categories,
        minPayment: action.payload.price_from,
        maxPayment: action.payload.price_to,
        currentStep: 7,
        ...optionFiltering,
      };
    }
    return { ...initialState };
  }

  if (action.type === 'RESET_STEP_TRACKER') {
    newState = { ...state };

    switch (action.payload.step) {
      case 1:
        newState.bodyTypeCategories = [];
        newState.transmission = 'Transmission';
        newState.fuel = 'Fuel';
        newState.budget = 'Budget';
        newState.minPayment = 100;
        newState.maxPayment = 350;
        newState.mileage = 'Mileage';
        newState.term = 'Term';
        break;
      case 2:
        newState.bodyTypeCategories = [];
        newState.transmission = 'Transmission';
        newState.fuel = 'Fuel';
        newState.budget = 'Budget';
        newState.minPayment = 100;
        newState.maxPayment = 350;
        newState.mileage = 'Mileage';
        break;
      case 3:
        newState.bodyTypeCategories = [];
        newState.transmission = 'Transmission';
        newState.fuel = 'Fuel';
        newState.budget = 'Budget';
        newState.minPayment = 100;
        newState.maxPayment = 350;
        break;
      case 4:
        newState.bodyTypeCategories = [];
        newState.filteredFuelTypes = [];
        newState.filteredTransmission = [];
        newState.transmission = 'Transmission';
        newState.fuel = 'Fuel';
        break;
      case 5:
        newState.bodyTypeCategories = [];
        newState.filteredFuelTypes = action.payload.data;
        newState.filteredTransmission = [];
        newState.transmission = 'Transmission';
        break;
      case 6:
        newState.bodyTypeCategories = [];
        newState.filteredTransmission = action.payload.data;
        break;
      default:
    }

    return newState;
  }

  if (action.type === 'SET_FMPC_CURRENT_STEP') {
    newState = { ...state };
    newState.currentStep = action.payload;
    return newState;
  }

  if (action.type === 'SET_TYPE') {
    newState = { ...state };
    newState.type = action.payload;
    return newState;
  }

  if (action.type === 'SET_TERM') {
    newState = { ...state };
    newState.term = action.payload;
    return newState;
  }

  if (action.type === 'SET_MILEAGE') {
    newState = { ...state };
    newState.mileage = action.payload;
    return newState;
  }

  if (action.type === 'SET_MIN_PAYMENT') {
    newState = { ...state };
    newState.minPayment = action.payload;
    newState.budget = `${newState.minPayment}-${state.maxPayment}`;
    return newState;
  }

  if (action.type === 'SET_MAX_PAYMENT') {
    newState = { ...state };
    newState.maxPayment = action.payload;
    newState.budget = `${state.minPayment}-${newState.maxPayment}`;
    return newState;
  }

  if (action.type === 'SET_FUEL') {
    newState = { ...state };
    newState.fuel = action.payload;
    return newState;
  }

  if (action.type === 'SET_TRANSMISSION') {
    newState = { ...state };
    newState.transmission = action.payload;
    return newState;
  }

  if (action.type === 'SET_BODY_TYPE_CATEGORIES') {
    newState = { ...state };
    newState.bodyTypeCategories = action.payload;
    return newState;
  }

  if (action.type === 'SET_FILTERED_FUELTYPES') {
    newState = { ...state };
    newState.filteredFuelTypes = action.payload;
    return newState;
  }

  if (action.type === 'SET_FILTERED_TRANSMISSION') {
    newState = { ...state };
    newState.filteredTransmission = action.payload;
    return newState;
  }

  if (action.type === 'SET_FILTERED_BODYTYPE') {
    newState = { ...state };
    newState.filteredBodyType = Array.from(new Set(action.payload.flat()));
    return newState;
  }

  return state;
};

export default findYourPerfectCar;
